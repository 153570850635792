<template>
  <TTView>
    <VRow>
      <VCol>
        <IndicatorForm
          :entity="indicator"
          :assessments="assessments"
          :loading="loading"
          :read-only="['skillAssessmentId']"
          @update:name="indicator.name = $event"
          @update:weight="indicator.weight = $event"
          @update:description="indicator.description = $event"
          @update:skillAssessmentId="indicator.skillAssessmentId = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import IndicatorForm from '../../../components/expert-assessment/indicators/IndicatorForm.vue';

export default {
  name: 'IndicatorsEdit',

  components: {
    IndicatorForm,
  },

  data() {
    return {
      loading: false,
      assessments: [],
      indicator: {
        name: null,
        description: null,
        weight: 0,
        skillAssessmentId: null,
      },
    };
  },
  computed: {
    skillAssessmentId() {
      return this.$route.params.assessmentId;
    },

    indicatorId() {
      return this.$route.params.indicatorId;
    },
  },

  async created() {
    await this.fetch();

    this.indicator.skillAssessmentId = this.skillAssessmentId;
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { indicatorId } = this;
        const data = { id: indicatorId };

        const { skillAssessments } = await this.$di.api.ExpertAssessment.skillAssessmentIndex();
        const { indicator } = await this.$di.api.ExpertAssessment.indicatorsGet({ data });

        this.assessments = skillAssessments;
        this.indicator = indicator;
      } catch (err) {
        // no-error
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const data = {
          id: this.indicatorId,
          indicator: {
            name: this.indicator.name,
            description: this.indicator.description,
            weight: this.indicator.weight,
          },
        };

        await this.$di.api.ExpertAssessment.indicatorsUpdate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
